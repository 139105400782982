import React from 'react'
// import { navigate } from 'gatsby-link'
import ReCAPTCHA from "react-google-recaptcha";

import Layout from '../../components/Layout'

export default class ContactPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      isValidated: false, 
      captchaToken: null, 
      formStatus: null,
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://q5yr743hv0.execute-api.us-east-1.amazonaws.com/dev/email/send', {
        method: 'POST',
        body: JSON.stringify({ 
          name: this.state.name, 
          email: this.state.email, 
          message: this.state.message,
          token: this.state.captchaToken,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .then(json => {
          if (json.result !== 'success') throw new Error();
        })
        .then(() => this.setState({ formStatus: 'success' }))
        .catch(() => this.setState({ formStatus: 'error' }));
  }

  handleCaptcha = (value) => {
    this.setState({ captchaToken: value });
  }

  render() {
    const image = this.props.data.markdownRemark.frontmatter.image;

    return (
      <Layout>
        <div>
          <div className="container">
            <div
              className="margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'top top',
                maxWidth: '100%',
                height: '250px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '150px',
                  lineHeight: '1',
                  justifyContent: 'space-around',
                  alignItems: 'left',
                  flexDirection: 'column',
                }}
              >
              </div>
            </div>
          </div>
          <div className="container">
            <div
              style={{
                backgroundColor: '#bd906f',
                color: '#ffffff',
                padding: '16px 32px'
              }}
            >
              616.402.1389 
              <a 
                href="mailto:kyle@lakeshoreleadership.com"
                style={{
                  color: '#ffffff',
                  paddingLeft: '32px'
                }}
              >kyle@lakeshoreleadership.com</a>
            </div>
          </div>
          <section className="section">
            <div className="container">
              <div className="content">
                <div dangerouslySetInnerHTML={{__html: this.props.data.markdownRemark.html}} />
              </div>
              <div className="content">
                <form 
                  id="form" 
                  action="" 
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <div className="field">
                    <label className="label" htmlFor="name">Name</label>
                    <div className="control">
                      <input className="input" type="text" name="name" id="name" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="email">Email</label>
                    <div className="control">
                      <input className="input" type="text" name="email" id="email" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="message">Message</label>
                    <div className="control">
                      <textarea className="textarea" name="message" id="message" rows="4" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <ReCAPTCHA
                        sitekey="6LdjkKIUAAAAADnNAevZcLpvIm2RbsM75USJkCUW"
                        onChange={this.handleCaptcha}
                      />
                    </div>
                  </div>
                  <p>
                    <button className="button is-link" type="submit">Send</button>
                  </p>
                </form>
                <div className="contact-status">
                  {this.state.formStatus === 'success' && <div className="contact-success">Email successfully sent</div>}
                  {this.state.formStatus === 'error' && <div className="contact-error">Error sending email</div>}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { title: { eq: "Contact" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
